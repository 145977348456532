export const apis = {
  GOOGLE_MAPS: "AIzaSyD_Q9mmL_6C-0_qVGKv4YmzuhuuOusexHo",
  FACEBOOK_APP_ID: 1123216931083985,
  FACEBOOK_CLIENT_TOKEN: "9b0d23d9c17d84a4db879e4457ebbc41",
};

export const mainStateKey = "appState";
export const modalPath = `editor.modal`;
export const enumsPath = `${mainStateKey}.enums`;
export const userDataPath = `${mainStateKey}.user`;

export const mediaTypes = {
  AFTER_VIDEO: "AFTER_VIDEO",
  TRAILER_VIDEO: "TRAILER_VIDEO",
  REGULAR_VIDEO: "REGULAR_VIDEO",
};

export const feedPostType = [
  { id: "article", name: "Article" },
  { id: "product_update", name: "Product Update" },
];

export const articleStatus = [
  { id: 1, name: "Draft" },
  { id: 2, name: "Finished" },
  { id: 3, name: "Inactive" },
];

export const filtersMapper = {
  scrape: "scrape_id",
  venue: "venue_uuids",
  country: "countries",
  city: "cities",
};

export const youtubeSuggestionStatus = [
  { id: "pending", name: "Pending" },
  { id: "matched", name: "Matched" },
  { id: "deleted", name: "Deleted" },
];

export const userStatus = [
  { id: "all", name: "All" },
  { id: "registered", name: "Registered" },
  { id: "premium", name: "Premium" },
];

export const feedpostStatus = [
  { id: "draft", name: "Draft" },
  { id: "finished", name: "Finished" },
  { id: "inactive", name: "Inactive" },
];

export const artistStatus = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "deleted", name: "Deleted" },
];

export const venueStatus = [
  { id: "open", name: "Open" },
  { id: "closed", name: "Closed" },
  { id: "deleted", name: "Deleted" },
];

export const festivalStatus = [
  { id: "active", name: "Active" },
  { id: "deleted", name: "Deleted" },
];

export const labelStatus = [
  { id: "active", name: "Active" },
  { id: "defunct", name: "Defunct" },
  { id: "deleted", name: "Deleted" },
];

export const trackStatus = [
  { id: "unverified", name: "Unverified" },
  { id: "verification_pending", name: "Verification pending" },
  { id: "verified", name: "Verified" },
  { id: "deleted", name: "Deleted" },
];

export const venuePriceRange = [
  { id: 1, name: "$" },
  { id: 2, name: "$$" },
  { id: 3, name: "$$$" },
];

export const yesNoStatus = [
  { id: "true", name: "Yes" },
  { id: "false", name: "No" },
];

export const viberatecomSiteUrl = {
  development: "http://com.dev.vibe.rate/",
  test: "https://staging.viberate.com/",
  production: "https://www.viberate.com/",
};

export const analyticsSiteUrl = {
  development: "https://next.dev.vibe.rate/",
  test: "https://next.cluster.viberate.com/",
  production: "https://www.viberate.com/",
};

export const musicAnalyticsToolsUrl = {
  development: "https://musicanalyticstools.dev.vibe.rate/",
  test: "https://staging.musicanalyticstools.com/",
  production: "https://www.musicanalyticstools.com/",
};

export const ARTICLE_DOMAIN = {
  VIBERATE: { id: 1 },
  ANALYTICS_TOOLS: { id: 2 },
};

export const DOMAIN = {
  "1": analyticsSiteUrl,
  "2": musicAnalyticsToolsUrl,
};

export const openingHoursInputDefinitions = [
  { day: 1, name: "MON", open: true, start: null, end: null },
  { day: 2, name: "TUE", open: true, start: null, end: null },
  { day: 3, name: "WED", open: true, start: null, end: null },
  { day: 4, name: "THU", open: true, start: null, end: null },
  { day: 5, name: "FRI", open: true, start: null, end: null },
  { day: 6, name: "SAT", open: true, start: null, end: null },
  { day: 7, name: "SUN", open: true, start: null, end: null },
];

export const defaultLineupHeader = [
  { name: "Name", prop: "name" },
  { name: "Day", prop: "day" },
  { name: "Stage", prop: "stage_id" },
  { name: "Start Date", prop: "start" },
  { name: "End Date", prop: "end" },
];

export const addFestivalLineupHeader = [
  { name: "Name", prop: "name" },
  { name: "Day", prop: "day" },
  { name: "Start Date", prop: "start" },
  { name: "End Date", prop: "end" },
];

export const videoCategory = [
  { id: 3, name: "Trailer movie" },
  { id: 2, name: "After movie" },
  { id: 5, name: "Video" },
];

export const sponsorTypes = [
  { id: "gold", name: "Gold" },
  { id: "silver", name: "Silver" },
  { id: "bronze", name: "Bronze" },
];

export const entityTypes = [
  { id: "artist", name: "Artist" },
  { id: "event", name: "Event" },
  { id: "venue", name: "Venue" },
  { id: "label", name: "Label" },
  { id: "festival", name: "Festival" },
  { id: "track", name: "Track" },
];

export const contributionTypes = [
  { id: "add", name: "Add" },
  { id: "edit", name: "Edit" },
  { id: "claim", name: "Claim" },
  { id: "delete", name: "Delete" },
];

export const ignoredArticleTagPages = ["home", "interviews", "insights", "how-tos", "festivals", "contributors"];

export const defaultListingTableRowNumber = 100;

export const disabledReviewTypes = ["vote", "claim", "delete"];

export const manipulateContributionHistoryFields = ["opening_hours", "tickets", "lineup"];

export const markerTypes = [
  {
    id: 1,
    name: "Amenities",
    backendValue: "amenity",
  },
  {
    id: 2,
    name: "Experiences",
    backendValue: "experience",
  },
  {
    id: 3,
    name: "Stages",
    backendValue: "stage",
  },
];

export const markerIcons = {
  amenities: {
    1: "marker-cashless.svg",
    2: "marker-credit-card.svg",
    3: "marker-cash-only.svg",
    4: "marker-atm.svg",
    5: "marker-lockers.svg",
    6: "marker-mobile-charging.svg",
    7: "marker-smoking.svg",
    8: "marker-parking.svg",
    9: "marker-first-aid.svg",
    10: "marker-shuttle-service.svg",
    11: "marker-shower.svg",
    12: "marker-lost-found.svg",
    13: "marker-laundry.svg",
    14: "marker-wifi.svg",
    15: "marker-grocery.svg",
    16: "marker-food-court.svg",
    17: "marker-water-station.svg",
    18: "marker-info-desk.svg",
    19: "marker-bike-rentals.svg",
    20: "marker-wheelchair.svg",
    21: "marker-vegan.svg",
    22: "marker-toilets.svg",
  },
  experiences: "marker-star.svg",
  stages: "marker-stage.svg",
};

export const festivalAppMapTypes = [
  { name: "Static Map", type: "static" },
  { name: "Interactive Map", type: "interactive" },
  { name: "Image Map", type: "image" },
];

export const latDiffSouth = 0.0037;
export const lngDiffWest = 0.0011;
export const latDiffNorth = 0.0014;
export const lngDiffEast = 0.0043;

export const youtubeDomain = "https://www.youtube.com/";
