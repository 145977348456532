import React from "react";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import ArticleListBox from "./articlesListBox";
import { ARTICLE_DOMAIN } from "../../../../constants/constants";

function EditAnalyticsToolsArticle() {
  useResetPageState();

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Edit Music Analytics Tools Article</h1>
      </section>
      <section className="content">
        <ArticleListBox domain={ARTICLE_DOMAIN.ANALYTICS_TOOLS.id} />
      </section>
    </React.Fragment>
  );
}

export default EditAnalyticsToolsArticle;
